<template>
  <NuxtLink
    :to="parsedLink.href"
    :target="parsedLink.target"
    :rel="parsedLink.rel"
    :class="[parsedLink.class, activeClassName]"
    :title="parsedLink.title"
    class="t3-link"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import type { T3Link } from '@t3headless/nuxt-typo3'
import { computed } from 'vue'

import { useRoute, useT3Options } from '#imports'

import type { T3LinkProps } from '~/types'
import {
  getValidLink,
  isCompanyPage,
  isInternalPage,
  isTargetAttributeValid
} from '~ui/helpers/linkUtils'

const props = defineProps<T3LinkProps>()

const { options } = useT3Options()

const parsedLink = computed(() => {
  const link = props.to ?? props.link

  const rawHref = getValidLink(link)
  if (!rawHref)
    return { href: '/', target: null, rel: null, class: null, title: null }

  const isInternal = isInternalPage(rawHref, options.baseUrl!)

  const href = isInternal ? rawHref.replace(options.baseUrl!, '') : rawHref

  const blankTargetAttrs =
    isInternal || isCompanyPage(href)
      ? { target: '_blank', rel: 'noopener' }
      : { target: '_blank', rel: 'noopener noreferrer nofollow' }

  let attrs = {
    target: (link as T3Link)?.target || null,
    class: (link as T3Link)?.class || null,
    title: (link as T3Link)?.title || null
  }

  if (
    attrs.target === '_blank' ||
    (!attrs.target && !isInternal) ||
    !isTargetAttributeValid(attrs.target)
  ) {
    attrs = { ...attrs, ...blankTargetAttrs }
  }

  return { href, ...attrs }
})

const route = useRoute()

const activeClassName = computed(() => {
  if (!props.activeClass) return null

  let className = 'router-link-active'

  if (typeof props.activeClass === 'string') {
    className = props.activeClass
  }

  const [path, href] = [route.path, parsedLink.value.href]
  return path.startsWith(href) ? className : null
})
</script>
